import { LandingPageQuery } from 'queries/resources'
import { PodcastCardsAllQuery } from 'queries/resources'
import { GQLClient } from 'services/graphql'
import { Flatten, PickFirst, Slugify, StripTrailingSlash } from 'services/utils'
import LandingPagesContents from 'templates/LandingPagesContents/LandingPagesContents'
import { GetCachedData, GetMetaData } from 'services/data.service'
import Container from 'containers/Container'
import GridContainer from 'containers/GridContainer'

const PodcastsLanding = ({
  pageData,
  type,
  topNav,
  mainNav,
  meta,
  pathData,
  featured = [],
  resources = [],
  topics = [],
  categories = [],
  authors = [],
  footerContent = {},
  applicationSettings = {}
}) => {
  const { headline, subheadline, footerCTA = [] } = pageData || {}
  let podcastlinks = []
  let displayedSupportingContent = []
  // format the podcast links to match detail page
  pageData.supportingContent.map((block) => {
      if(block.__typename === "CardsContentFeature"){
        block.cards.map((card) => {
          podcastlinks.push({
            link: card.linkUrl,
            type: [{
              logo: card.icon[0].svg,
              name: card.headline,
              mainlink: card.linkUrl
            }]
          })
        })
      }
      // we need the cards from the Where to Listen block for the gutter but don't want to show that block on the Podcasts page
      if(block.headline != "Podcast Sidebar") {
        displayedSupportingContent.push(block)
      }
    }
  )
  return (
      <LandingPagesContents
        headline={headline}
        subheadline={subheadline}
        featured={featured}
        supportingContent={displayedSupportingContent}
        resources={{ resource: resources }}
        topics={topics}
        categories={categories}
        authors={authors}
        meta={meta}
        topNav={topNav}
        mainNav={mainNav}
        footerCTA={footerCTA}
        footerContent={footerContent}
        applicationSettings={applicationSettings}
        hasFilters={false}
        isNews={false}
      />
  )
}
export default PodcastsLanding

export async function getStaticProps(context) {
  const client = await GQLClient()
  const data = await client.request(LandingPageQuery('podcast'))
  const resources = await client.request(PodcastCardsAllQuery)
  const content = Flatten(PickFirst(data.queryLandingPagesContents))
  const allResources = resources.queryResourceContents
    .map((resource) => {
      const tmp = Flatten(resource)
      if (tmp.pageBase && tmp.pageBase.length > 0) {
        tmp.pageBase[0].url = StripTrailingSlash(tmp.pageBase[0].url)
      }
      return tmp
    })
    .sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt)
    })
  const filters = require('data/filters.json') || {}
  let pageData = {
    type: '',
    pageData: content,
    meta: GetMetaData(content.pageBase),
    pathData: require('data/paths.json'),
    resources: allResources,
    featured: [],
    authors: Object.entries(filters.author).map(([slug, name]) => {
      return { slug, name }
    }),
    topics: Object.entries(filters.topic).map(([slug, name]) => {
      return { slug, name }
    }),
    categories: Object.entries(filters.category).map(([slug, name]) => {
      return { slug, name }
    })
  }
  pageData = GetCachedData(pageData)
  return {
    props: pageData || {}
  }
}
